import { Alert, Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import React, { createContext, useContext, useState } from 'react';
import { useNavigation } from "../FireBaseApp/Navigation";


const CreatorPageContext = createContext()
export function useCreatorPageContext() { return useContext(CreatorPageContext) }

export function CreatorPage({ itemName, dataMap, addToDatabaseFunc, children }) {
    const { tryNavigateTo, setBlock, alert } = useNavigation()
    const [errorMessage, setErrorMessage] = useState("")
    let defaultDataMap = {}
    dataMap.map(item =>
        defaultDataMap[item.Name] = item?.DefaultValue || ""
    )
    const [data, setData] = useState(defaultDataMap)
    //TODO add protection for clearing the fields
    function setValue(key, value) {
        if (data[key] !== value) {
            let newData = { ...data }
            newData[key] = value
            setData(newData)
            setBlock(true)
        }
    }
    const addToDatabase = async () => {
        setErrorMessage("");
        for (var index in dataMap) {
            if (dataMap[index].Required && data[dataMap[index].Name] === "") {
                setErrorMessage(dataMap[index].Name + " Field is Required")
                return
            }
        }
        if (addToDatabaseFunc === undefined) {
            setErrorMessage("Function is Missing")
        }
        //TODO use promises?
        setErrorMessage(addToDatabaseFunc(data))
    }

    return (
        <CreatorPageContext.Provider value={{ setValue, data }}>
            <Paper sx={{ marginBottom: 1, padding: 2 }}>
                <Typography variant="h2">Create New {itemName}</Typography>
                <Stack direction='row' alignItems='center' spacing={1}>
                    <Button size='small' onClick={() => { tryNavigateTo("/" + itemName + "s") }}>all {itemName}s</Button>
                </Stack>
            </Paper>
            <Paper sx={{ padding: 2, flex: 1, display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
                <Grid container spacing={1}>
                    {data !== undefined && children}
                </Grid>
                <Box >
                    {errorMessage && <Alert severity="warning">{errorMessage}</Alert>}
                    {alert && <Alert severity="warning">You have unsaved changes</Alert>}

                    <Button variant='contained' onClick={addToDatabase}>Add</Button>
                </Box >
            </Paper>
        </CreatorPageContext.Provider>
    )

}
import AddIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import UserIcon from '@mui/icons-material/Person';
import { Avatar, Box, IconButton, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import React from 'react';

// TODO 
export function ArrayEditor({ title, data }) {
    return (
        <Box>
            <Stack direction="row" alignItems="center">
                <Typography variant="h3">{title}</Typography>
                <IconButton><AddIcon /></IconButton>
            </Stack>
            <List dense>
                {Object.entries(data).map(([key, value]) =>
                    <ArrayItem key={key} primary={value} secondary={key}></ArrayItem>
                )}
            </List>
        </Box>
    )
}
function ArrayItem({ primary, secondary }) {
    return (
        <ListItem
            secondaryAction={
                <IconButton edge="end" aria-label="delete">
                    <DeleteIcon />
                </IconButton>
            }
        >
            <ListItemAvatar>
                <Avatar>
                    <UserIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={primary}
                secondary={secondary}
            />
        </ListItem>
    )
}
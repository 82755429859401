import React from 'react';
import { useParams } from 'react-router-dom';
import { useBAccountData } from '../../FireBaseApp/AuthProtector';

import { doc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import ModalImage from 'react-modal-image';
import { NameFromPhoneNumber } from '../../components/BAccountConversions/PhoneNumberName';
import { TimeConversion } from '../../components/BAccountConversions/TimeConversion';

import { Grid, ImageList, ImageListItem, TextField } from '@mui/material';


import { EditorPage, useEditorPageContext } from '../../components/EditorPage';

export default function Message() {
  const bAccountData = useBAccountData()
  const firestore = useFirestore()
  const { MessageID } = useParams()

  const document = doc(firestore, 'BAccounts', bAccountData.id, 'Messages', MessageID)

  return (
    <EditorPage itemName="Message" document={document} >
      <MessageDetails />
    </EditorPage>
  );
}

function MessageDetails() {
  const { setValue, data } = useEditorPageContext()

  return (
    <>
      <Grid container spacing={1}>
        <Grid container spacing={1} item xs={12} lg={6} >
          <Grid item xs={12} md={6}>
            <TextField fullWidth label={"Sender"} inputProps={{ readOnly: true }}
              value={NameFromPhoneNumber(data.FromNumber) + " " + data.FromNumber} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label={"Received"} inputProps={{ readOnly: true }}
              value={TimeConversion(data.ReceivedAt)} />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField fullWidth multiline label={"Message"} inputProps={{ readOnly: true }}
              value={data.Text} />
          </Grid>
        </Grid >
        <Grid container spacing={1} item xs={12} lg={6}>
          <Grid item xs={12} >
            <TextField fullWidth multiline minRows={4} id="Notes" label="Notes"
              value={data?.Notes}
              onChange={(event) => { setValue(event.target.id, event.target.value) }} />
          </Grid >
        </Grid>
      </Grid >
      <ImageList sx={{ width: 500, height: 450 }} cols={3} >
        {data?.Images !== undefined && data?.Images.map((item) => (
          <ImageListItem key={item.url}>
            <ModalImage small={item.url} large={item.url} />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  )
}

import React from 'react';
import { useBAccountData, useUserData } from '../../FireBaseApp/AuthProtector';

import { collection } from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import { Grid, TextField } from '@mui/material';
import { addDoc, doc, runTransaction, Timestamp } from 'firebase/firestore';

import { CreatorPage, useCreatorPageContext } from '../../components/CreatorPage';
import { useNavigation } from '../../FireBaseApp/Navigation';

export default function JobCreate() {
    const BAccountData = useBAccountData();
    const firestore = useFirestore();
    const userData = useUserData()
    const { navigateTo } = useNavigation()
    const dataCollection = collection(firestore, 'BAccounts', BAccountData.id, 'Jobs');

    const dataMap = [
        { Name: "Customer", Required: true, Type: "TextField" },
        { Name: "ReferenceID", Type: "TextField" },
        { Name: "Somethingelse", Type: "TextField" },
        { Name: "Anotherthing", Type: "TextField" },
        { Name: "Notes", Type: "TextField", },
    ]
    //TODO use promises
    const addToDatabaseFunc = (data) => {
        let JobID
        runTransaction(firestore, async (transaction) => {
            const BAccountDocRef = doc(firestore, 'BAccounts', BAccountData.id, "Settings", "Profile")
            const BAccountDoc = await transaction.get(BAccountDocRef)
            if (!BAccountDoc.exists()) {
                throw new Error("Account Information does not exists")
            }

            let JobPrefix = BAccountDoc.data()?.JobPrefix
            let NextJobNumber = BAccountDoc.data()?.NextJobNumber

            if (NextJobNumber === undefined) {
                NextJobNumber = 1
            }

            transaction.update(BAccountDocRef, { NextJobNumber: NextJobNumber + 1 })
            JobID = [JobPrefix + NextJobNumber.toString()].join("").trim()

        }).then(() => {
            return addDoc(dataCollection, {
                ...data,
                JobID: JobID,
                Created: Timestamp.now(),
                CreatedBy: {
                    UserID: userData.id,
                    UserEmail: userData.email,
                },
                Status: "Not Started",
                IsComplete: false,
            })
        }).then(({ id }) => {
            navigateTo("/Jobs/" + id)
        }).catch((error) => {
            return error.message
        })
    }

    return (
        <CreatorPage itemName={"Job"} collection={dataCollection} dataMap={dataMap} addToDatabaseFunc={addToDatabaseFunc}>
            <JobsCreateForm />
        </CreatorPage >
    );
}
function JobsCreateForm() {
    const { setValue, data } = useCreatorPageContext()
    return (
        <>
            <Grid container item xs={12} xl={6} spacing={1}>
                <Grid item xs={12} lg={4}>
                    <TextField fullWidth size="small" id="Customer" label="Customer Name"
                        value={data?.Customer}
                        onChange={(event) => { setValue(event.target.id, event.target.value) }} />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <TextField fullWidth size="small" id="ReferenceID" label="Customer Reference ID"
                        value={data?.ReferenceID}
                        onChange={(event) => { setValue(event.target.id, event.target.value) }} />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <TextField fullWidth size="small" id="Somethingelse" label="One thing"
                        value={data?.Somethingelse}
                        onChange={(event) => { setValue(event.target.id, event.target.value) }} />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <TextField fullWidth size="small" id="Anotherthing" label="Or another"
                        value={data?.Anotherthing}
                        onChange={(event) => { setValue(event.target.id, event.target.value) }} />
                </Grid>
            </Grid>
            <Grid item xs={12} xl={6}>
                <TextField fullWidth multiline minRows="4" size="small" id="Notes" label="Notes"
                    value={data?.Notes}
                    onChange={(event) => { setValue(event.target.id, event.target.value) }} />
            </Grid>
        </>
    )
}

/*addToDatabase = async () => {
                clearAlert();
                setErrorMessage("");
                for (var index in dataMap) {
                    if (dataMap[index].Required && data[dataMap[index].Name] === "") {
                        setErrorMessage(dataMap[index].Name + " Field is Required")
                        return
                    }
                }
                let JobPrefix
                let NextJobNumber
                runTransaction(firestore, async (transaction) => {
                    const BAccountDocRef = doc(firestore, 'BAccounts', BAccountData.id, "Settings", "Profile")
                    const BAccountDoc = await transaction.get(BAccountDocRef)
                    if (!BAccountDoc.exists()) {
                        throw new Error("Account Information does not exists")
                    }
        
                    JobPrefix = BAccountDoc.data()?.JobPrefix
                    NextJobNumber = BAccountDoc.data()?.NextJobNumber
        
                    if (BAccountDoc.data()?.NextJobNumber !== undefined) {
                        transaction.update(BAccountDocRef, { NextJobNumber: NextJobNumber + 1 })
                    }
        
                }).then(() => {
                    addDoc(collection, {
                        ...data,
                        JobID: JobPrefix || "" + NextJobNumber || "",
                        Created: Timestamp.now(),
                        CreatedBy: {
                            UserID: userData.id,
                            UserEmail: userData.email,
                        },
                        Status: "Created",
                        IsComplete: false,
                    }).then(({ id }) => {
                        tryNavigateTo("/Jobs/" + id)
                    }).catch((error) => {
                        setErrorMessage(error.message)
                    })
                }).catch((error) => {
                    setErrorMessage(error.message)
                })
                */
import React from 'react';
import { useBAccountData } from '../../FireBaseApp/AuthProtector';

import MessagesIcon from '@mui/icons-material/Email';

import { Box, Card, CardActionArea, CardContent, Grid, IconButton, Paper, Stack, Typography } from '@mui/material';
import { useNavigation } from '../../FireBaseApp/Navigation';
import { TimeConversion } from "../../components/BAccountConversions/TimeConversion";

import { collection } from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import AddIcon from '@mui/icons-material/AddCircle';

import { DataLoader } from '../../components/TableLoader';


export default function Jobs() {
  const BAccountData = useBAccountData()
  const firestore = useFirestore();
  const jobsCollection = collection(firestore, 'BAccounts', BAccountData.id, 'Jobs')

  const { tryNavigateTo } = useNavigation()

  const columns = [
    {
      field: 'JobID',
      headerName: "Job ID",
      description: "The Job the message belongs to",
      flex: .5,
      renderCell: ({ value }) => (value)
    },
    {
      field: 'ReferenceID',
      headerName: "Reference ID",
      description: "The Reference ID",
      flex: .5,
    },
    {
      field: 'Customer',
      headerName: "Customer Name",
      description: "The Customer Name",
      flex: 1,
    },
    {
      field: 'Status',
      headerName: "Status",
      description: "Status of the Job",
      flex: .5,
    },
    {
      field: "Created",
      headerName: "Created",
      description: "Date Created in System",
      flex: 1,
      valueGetter: ({ value }) => {
        return TimeConversion(value);
      },
    },
    {
      field: 'id',
      headerName: "FireStore ID",
      headerAlign: 'center',
      flex: 1,
    },
  ]

  const initialState = {
    columns: {
      columnVisibilityModel: {
        id: false,
      }
    }
  }


  return (
    <>
      <Paper sx={{ marginBottom: 1, padding: 2 }}>
        <Stack direction='row' alignItems='center' spacing={1}>
          <MessagesIcon color='secondary' />
          <Typography variant="h2">Jobs</Typography>
          <IconButton onClick={() => { tryNavigateTo("/Jobs/Create") }}><AddIcon color='primary' /></IconButton>
        </Stack>
      </Paper>
      <Box sx={{ flexGrow: 1 }}>
        <DataLoader
          collection={jobsCollection}
          columns={columns}
          individualPath={"/Jobs/"}
          initialState={initialState}
          Card={DataCard}
        />
      </Box>
    </>
  );
}

function DataCard({ data, individualPath }) {
  const { tryNavigateTo } = useNavigation();

  return (
    <Card sx={{ midwidth: 275 }}>
      <CardActionArea
        onClick={() => {
          tryNavigateTo(individualPath + data.id);
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>{data.ReferenceID}</Typography>
              <Typography>({data.JobID})</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{TimeConversion(data.Created)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{data.Customer}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{data.Status}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
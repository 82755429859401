import { ThemeProvider, createTheme } from '@mui/material/styles';
import React, { createContext, useContext } from 'react';

const baseTheme = createTheme({
  typography: {
    h1: {
      fontWeight: 500,
      fontSize: "2.125rem",
      lineHeight: 1.235,
      letterSpacing: "0.0075em",
    },
    h2: {
      fontWeight: 500,
      fontSize: "1.5rem",
      lineHeight: 1.334,
      letterSpacing: "0.0075em",
    },
    h3: {
      fontWeight: 500,
      fontSize: "1.25rem",
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
    },
    h4: {
      fontWeight: 500,
      fontSize: "1rem",
      lineHeight: 1.335,
      letterSpacing: "0.0075em",
    },
    h5: {
      fontWeight: 500,
      fontSize: "1rem",
      lineHeight: 1.335,
      letterSpacing: "0.0075em",
    },
    h6: {
      fontWeight: 500,
      fontSize: "1rem",
      lineHeight: 1.335,
      letterSpacing: "0.0075em",
    }
  },
  palette: {
    secondary: {
      main: '#9e9e9e',
      light: '#9e9e9e',
      dark: '#9e9e9e',
      contrastText: '#fff',
    },
    background: {
      default: '#F2F2F2',
    },
    created: {
      main: '#BDBDBD',
      light: '#EFEFEF',
      dark: '#8D8D8D',
      contrastText: '#FFFFFF',
    },
    ready: {
      main: '#448AFF',
      light: '#83B9FF',
      dark: '#005ECB',
      contrastText: '#FFFFFF',
    },
    covered: {
      main: '#FF9100',
      light: '#FFC246',
      dark: '#C56200',
      contrastText: '#000000',
    },
    inTransit: {
      main: '#FFFF00',
      light: '#FFFF5A',
      dark: '#C7CC00',
      contrastText: '#000000',
    },
    delivered: {
      main: '#76FF03',
      light: '#B0FF57',
      dark: '#32CB00',
      contrastText: '#000000',
    },
    readyToInvoice: {
      main: '#64FFDA',
      light: '#9EFFFF',
      dark: '#14CBA8',
      contrastText: '#000000',
    },
    invoiceSent: {
      main: '#AB47BC',
      light: '#DF78EF',
      dark: '#790E8B',
      contrastText: '#FFFFFF',
    },
    paymentReceived: {
      main: '#FF4081',
      light: '#FF79B0',
      dark: '#C60055',
      contrastText: '#FFFFFF',
    },
    completed: {
      main: '#212121',
      light: '#484848',
      dark: '#000000',
      contrastText: '#FFFFFF',
    },
    cancelled: {
      main: '#D50000',
      light: '#FF5131',
      dark: '#9B0000',
      contrastText: '#FFFFFF',
    }
  }
})

const ThemeContext = createContext()
export function useThemeContext() { return useContext(ThemeContext) }
export function AppThemeProvider({ children }) {

  const theme = baseTheme

  theme.palette.ready = {
    main: '#9c27b0',
    light: '#9c27b0',
    dark: '#9c27b0',
    contrastText: '#fff',
  }

  let themeData = {}
  return (
    <ThemeContext.Provider value={themeData}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}
import { Button, Typography } from "@mui/material";
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import React, { useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'reactfire';

import { Alert, Box, Grid, Paper, Stack, TextField, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Copyright } from './Copyright';
import { useFirebaseAppContext } from './FireBaseApp';

export function UnAuthenticated() {
  const theme = useTheme()
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const { signinImage, logo } = useFirebaseAppContext()
  let formWidth = largeScreen ? "60%" : "80%"
  return (
    <Grid container component="main" sx={{ p: 0, height: '100vh' }}>
      {largeScreen &&
        <Grid item xs={6} sx={{ width: "100%", height: '100%' }}>
          <Box sx={{ width: '100%', height: '100%', position: "relative" }}>
            <Box component="img" sx={{ width: '100%', height: '100%', position: "absolute" }} src={signinImage}></Box>
            <Paper sx={{ position: "absolute", top: "35%", left: "55%", border: 3, borderColor: 'black', borderRadius: 4, padding: 2 }}>
              <Stack>
                <Typography variant="h2" sx={{ maxwidth: "10px" }}>Track, manage, and save</Typography>
                <Typography variant="h2" sx={{ maxwidth: "10px" }}>your job history with a </Typography>
                <Typography variant="h2" sx={{ maxwidth: "10px" }}>simple text message. </Typography>
              </Stack>
            </Paper>
          </Box>
        </Grid>}
      <Grid item xs={12} lg={6}>
        <Paper sx={{ width: '100%', height: '100%' }}>
          <Stack alignItems='center' justifyContent='space-evenly' sx={{ height: '100%' }}>
            <Box component="img" sx={{ display: "block", width: '80%', height: 'auto' }} src={logo} />
            <Box sx={{ m: 1, width: formWidth }}>
              <Routes>
                <Route path="/ForgotPassword" element={<ForgotPassword />}></Route>
                <Route path="/CreateAccount" element={<CreateAccount />}></Route>
                <Route path="*" element={<SignIn />}></Route>
              </Routes>
            </Box>
            <Copyright sx={{ mt: 5 }} />
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  );
}

function ForgotPassword() {
  const auth = useAuth();
  const [errorMessage, setErrorMessage] = useState("")
  const navigateTo = useNavigate()

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get('email')

    if (email === "" || email === undefined) {
      setErrorMessage("Please Enter Your Email")
      return
    }

    try {
      setErrorMessage("")
      await sendPasswordResetEmail(auth, data.get('email')).then(() => {
        navigateTo('/')
      })
    }
    catch (error) {
      console.error(error.message)
      setErrorMessage(error.message)
    }
  }
  return (
    <>
      <Box component="form" noValidate onSubmit={handleSubmit}>
        <Typography component="h1" variant="h2">Forgot Password</Typography>
        <TextField margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />
        {errorMessage && <Alert severity="warning">{errorMessage}</Alert>}
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Submit
        </Button>
        <Links />
      </Box>
    </>
  );
}

function SignIn() {
  const auth = useAuth();
  const [errorMessage, setErrorMessage] = useState("")

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      setErrorMessage("")
      await signInWithEmailAndPassword(auth, data.get('email'), data.get('password'))
    } catch (error) {
      console.error(error.message)
      setErrorMessage("Failed to login - Please try again")
    }
  };
  return (
    <>
      <Box component="form" noValidate onSubmit={handleSubmit}>
        <Typography component="h1" variant="h2">Sign In</Typography>
        <TextField margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />
        <TextField margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" />
        {errorMessage && <Alert severity="warning">{errorMessage}</Alert>}
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Sign In
        </Button>
        <Links />
      </Box>
    </>
  );
}
function CreateAccount() {
  const auth = useAuth();
  const [errorMessage, setErrorMessage] = useState("")

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      setErrorMessage("")
      await createUserWithEmailAndPassword(auth, data.get('email'), data.get('password'))
    } catch (error) {
      console.error("FirebaseError:" + error.message)
      switch (error.code) {
        case "auth/email-already-in-use":
          setErrorMessage("Failed to Create Account - Email already in use")
          break;
        case "auth/weak-password":
          setErrorMessage("Failed to Create Account - Password too short")
          break;
        default:
          setErrorMessage("Failed to Create Account")
          break;
      }
    }
  };
  return (
    <>
      <Box component="form" noValidate onSubmit={handleSubmit}>
        <Typography component="h1" variant="h2">Create Account</Typography>
        <TextField margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />
        <TextField margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" />
        {errorMessage && <Alert severity="warning">{errorMessage}</Alert>}
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Create Account
        </Button>
        <Links />
      </Box>

    </>
  );
}

function Links() {
  const navigateTo = useNavigate()
  const location = useLocation();
  return (
    <Stack sx={{ width: "100%" }} direction="row" justifyContent="space-evenly" spacing={1}>
      {(location.pathname === "/ForgotPassword" || location.pathname === "/CreateAccount") && <Button variant="outlined" onClick={() => { navigateTo("/") }}> Sign In</Button>}
      {location.pathname !== "/ForgotPassword" && <Button variant="outlined" onClick={() => { navigateTo("/ForgotPassword") }}>Forgot Password</Button>}
      {location.pathname !== "/CreateAccount" && <Button variant="outlined" onClick={() => { navigateTo("/CreateAccount") }}>Create Account</Button>}
    </Stack>
  )

}
//Firebase
import CssBaseline from '@mui/material/CssBaseline';
import React, { createContext, useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { FirebaseAppProvider } from 'reactfire';
import { AuthProtector } from './AuthProtector';
import ErrorBoundary from './ErrorPage';
import { FirebaseComponents } from './FirebaseComponents';
import { NavigationProvider } from './Navigation';
import { config } from './firebaseConfig';

const FirebaseAppContext = createContext()
export function useFirebaseAppContext() { return useContext(FirebaseAppContext) }

export function FirebaseApp({ children, logo, signinImage }) {

  return (
    <FirebaseAppProvider firebaseConfig={config}>
      <FirebaseComponents>
        <CssBaseline />
        <ErrorBoundary>
          <Router>
            <FirebaseAppContext.Provider value={{ logo, signinImage }}>
              <AuthProtector>
                <NavigationProvider>
                  {children}
                </NavigationProvider>
              </AuthProtector>
            </FirebaseAppContext.Provider>
          </Router>
        </ErrorBoundary>
      </FirebaseComponents>
    </FirebaseAppProvider>
  )
}
import { format, parseISO } from 'date-fns';

export function TimeConversion(value) {

    if (value === undefined) {
        return "";
    }


    if (typeof value.toDate === "function") {
        let tempValue = value.toDate()
        if (tempValue !== undefined) {
            return format(tempValue, "Pp");
        }
    }

    let jsTime = parseISO(value)
    if (jsTime !== undefined) {
        return format(jsTime, "Pp");

    }

    return value;
}
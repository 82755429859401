import { signOut } from 'firebase/auth';
import { doc } from 'firebase/firestore';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth, useFirestore, useFirestoreDocData, useSigninCheck } from 'reactfire';

import { useFirebaseAppContext } from './FireBaseApp';
import { UnAuthenticated } from './UnAuthenticated';

import { Box, Button, LinearProgress, Stack, Typography } from '@mui/material';

const AuthDataContext = createContext()
export function useAuthData() { return useContext(AuthDataContext) }

export function AuthProtector({ children }) {
  const auth = useAuth()
  const { status, data: signInCheckResult } = useSigninCheck();

  if (status === 'success' && signInCheckResult.signedIn === false) {
    return <UnAuthenticated />
  }

  function SignOut() {
    signOut(auth)
  }

  if (status === 'success' && signInCheckResult.signedIn && signInCheckResult.user) {
    //Load data from firebaseBase
    return (
      <AuthDataContext.Provider value={SignOut}>
        <UserProfileProvider user={signInCheckResult.user}>
          <UserDataProvider user={signInCheckResult.user}>
            <BAccountSelector>
              {children}
            </BAccountSelector>
          </UserDataProvider>
        </UserProfileProvider>
      </AuthDataContext.Provider>
    )
  }

  return <Loading message={"Loading Auth"} />
}
const UserProfileContext = createContext()
export function useUserProfile() { return useContext(UserProfileContext) }

function UserProfileProvider({ children, user }) {
  const firestore = useFirestore();
  const docRef = doc(firestore, "UserData", user.uid, "Settings", "Profile");
  const { status, data } = useFirestoreDocData(docRef, { idField: `id-${user.stsTokenManager.expirationTime}` });
  //Need to move this do it doesn't get called on each reload
  if (status === 'success' && data) {
    let userProfile = { ...data, id: user.uid, email: user.email }
    return (
      <UserProfileContext.Provider value={userProfile}>
        {children}
      </UserProfileContext.Provider>
    )
  }
  return <Loading message={"Loading User Profile"} />
}

const UserDataContext = createContext()
export function useUserData() { return useContext(UserDataContext) }

function UserDataProvider({ children, user }) {
  const firestore = useFirestore();
  const docRef = doc(firestore, "UserData", user.uid);
  const { status, data } = useFirestoreDocData(docRef, { idField: `id-${user.stsTokenManager.expirationTime}` });
  //Need to move this do it doesn't get called on each reload
  if (status === 'success' && data) {
    let userData = { ...data, id: user.uid, email: user.email }
    return (
      <UserDataContext.Provider value={userData}>
        {children}
      </UserDataContext.Provider>
    )
  }
  return <Loading message={"Loading User Data"} />
}

const BAccountSelectorContext = createContext()
export function useBAccountSelector() { return useContext(BAccountSelectorContext) }

export function BAccountSelector({ children }) {
  const { data: signInCheckResult } = useSigninCheck();
  const userData = useUserData()
  const userProfile = useUserProfile()
  const [BAccountID, setBAccountID] = useState(undefined);

  function ExitBAccount() {
    setBAccountID(undefined)
  }

  useEffect(() => {
    if (userProfile.BAccountID !== undefined) {
      setBAccountID(userProfile.BAccountID)
    }
    if (userData.BAccountID !== undefined) {
      setBAccountID(userData.BAccountID)
    }
  }, [userProfile.BAccountID, userData.BAccountID])


  if (BAccountID !== undefined) {
    return (
      <BAccountSelectorContext.Provider value={{ ExitBAccount }}>
        <BAccountDataProvider user={signInCheckResult.user} BAccountID={BAccountID}>
          <BAccountProfileProvider user={signInCheckResult.user} BAccountID={BAccountID}>
            {children}
          </BAccountProfileProvider>
        </BAccountDataProvider>
      </BAccountSelectorContext.Provider>
    )
  }

  if (BAccountID === undefined) {
    return (<>
      Choose BAccount:
      {userData.BAccountIDs.map((item) =>
        <Button key={item} onClick={() => {
          setBAccountID(item)
        }}>{item}</Button>)}
    </>)
  }

}

const BAccountDataContext = createContext()
export function useBAccountData() { return useContext(BAccountDataContext) }

export function BAccountDataProvider({ children, BAccountID, user }) {
  const firestore = useFirestore();
  const docRef = doc(firestore, "BAccounts", BAccountID);
  const { status, data } = useFirestoreDocData(docRef, { idField: `id-${user.stsTokenManager.expirationTime}` });

  if (status === 'success' && data) {
    let BAccountData = { ...data, id: BAccountID }
    return (
      <BAccountDataContext.Provider value={BAccountData}>
        {children}
      </BAccountDataContext.Provider>
    )
  }
  return <Loading message={"Business Account Data"} />
}

const BAccountProfileContext = createContext()
export function useBAccountProfile() { return useContext(BAccountProfileContext) }

export function BAccountProfileProvider({ children, BAccountID, user }) {
  const firestore = useFirestore();
  const docRef = doc(firestore, "BAccounts", BAccountID, "Settings", "Profile");
  const { status, data } = useFirestoreDocData(docRef, { idField: `id-${user.stsTokenManager.expirationTime}` });

  if (status === 'success' && data) {
    let bAccountProfile = { ...data, id: BAccountID }
    return (
      <BAccountProfileContext.Provider value={bAccountProfile}>
        {children}
      </BAccountProfileContext.Provider>
    )
  }
  return <Loading message={"Business Account Profile"} />
}

function Loading({ message }) {
  const { logo } = useFirebaseAppContext();
  return (
    <Box sx={{ display: 'flex', height: '100vh', width: '100vw' }}>
      <Stack alignItems={"center"} justifyContent={"center"} sx={{ width: '65vw', flexGrow: 1 }}>
        <Stack alignItems={"center"} justifyContent={"center"} spacing={2} sx={{ width: 'inherit' }} >
          <Box component="img" sx={{ display: "block", width: 'inherit', height: 'auto' }} src={logo} />
          <LinearProgress sx={{ width: 'inherit' }} />
          <Typography variant={"h5"}>{message}</Typography>
        </Stack>
      </Stack>
    </Box>
  )
}
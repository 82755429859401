import { Grid, Paper, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useBAccountData, useBAccountProfile, useUserProfile } from '../FireBaseApp/AuthProtector';


export default function Dashboard() {
  const userProfile = useUserProfile()
  const BAccountData = useBAccountData()
  const BAccountProfile = useBAccountProfile()

  return (
    <>
      <Paper sx={{ marginBottom: 1, padding: 2 }}>
        <Stack spacing={1}>
          <Typography variant="h1" color='primary'>{BAccountProfile.Name}</Typography>
          <Typography variant="h2">Welcome {userProfile.Name}</Typography>
        </Stack>
      </Paper>
      <Grid container spacing={1} sx={{ marginBottom: 2 }}>
        <Grid item xs={12} md={6} sx={{ height: "100%" }}>
          <Paper sx={{ padding: 2, height: "100%" }}>
            <Typography variant="h2">Statistics</Typography>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Typography variant="h4">Messages Today:</Typography>
              <Typography > {BAccountData.Name}</Typography>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} sx={{ height: "100%" }}>
          <Paper sx={{ padding: 2, height: "100%" }}>
            <Typography variant="h2">Settings</Typography>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Typography variant="h4">Company Name:</Typography>
              <Typography > {BAccountProfile.Name}</Typography>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Typography variant="h4">JobsDone Number:</Typography>
              <Tooltip title="The number your JobsDone account can receive texts at" arrow>
                <Typography > {BAccountData.ToNumber}</Typography>
              </Tooltip>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Typography variant="h4">Alert Number:</Typography>
              <Tooltip title="The number your JobsDone account will forward text messages to" arrow>
                <Typography > {BAccountProfile.AlertNumber}</Typography>
              </Tooltip>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Typography variant="h4">Next Job Number:</Typography>
              <Tooltip title="The next Job number your JobsDone account will use" arrow>
                <Typography > {[BAccountProfile?.JobPrefix, BAccountProfile?.NextJobNumber].join("").trim()}</Typography>
              </Tooltip>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}



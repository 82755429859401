import React from 'react';
import { useBAccountData } from "../../FireBaseApp/AuthProtector";

import MessagesIcon from "@mui/icons-material/Email";

import { NameFromPhoneNumber } from "../../components/BAccountConversions/PhoneNumberName";
import { TimeConversion } from "../../components/BAccountConversions/TimeConversion";

import { Box, Card, CardActionArea, CardContent, Grid, Paper, Stack, Typography } from "@mui/material";
import { useNavigation } from "../../FireBaseApp/Navigation";

import { collection } from "firebase/firestore";
import { useFirestore } from "reactfire";

import { DataLoader } from "../../components/TableLoader";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function FromPerson({ value }) {
  const BAccountData = useBAccountData();
  return (
    <Stack direction="row" spacing={1}>
      <Typography>{NameFromPhoneNumber(value)}</Typography>
      {BAccountData?.Numbers[value] ? <CheckCircleIcon color="success" /> : ""}
      <Typography>{value}</Typography>
    </Stack>
  );
}

export default function Messages() {
  const BAccountData = useBAccountData();
  const firestore = useFirestore();
  const fscollection = collection(firestore, "BAccounts", BAccountData.id, "Messages");

  const columns = [
    {
      field: "FromNumber",
      headerName: "Sender",
      description: "The Number which sent this message",
      minWidth: 300,
      flex: 1.5,
      renderCell: ({ value, row }) => <FromPerson value={value} data={row} />,
    },
    {
      field: "ReceivedAt",
      hide: true,
      headerName: "Received",
      description: "Date Message was Received into System",
      flex: 1.2,
      valueGetter: ({ value }) => {
        return TimeConversion(value);
      },
    },
    {
      field: "FromNumberIsRegistered",
      headerName: "",
      description: "The Number is registered",
      flex: .5,
      renderCell: ({ value }) => {
        return value ? <CheckCircleIcon color="success" /> : ""
      },
    },
    {
      field: "Images",
      headerName: "Image(s)",
      headerAlign: "center",
      align: "center",
      description: "Message Images",
      flex: 0.7,
      valueGetter: ({ value }) => {
        return value.length;
      },
    },
    {
      field: "Text",
      headerName: "Message",
      description: "Message Text",
      flex: 2,
    },
    {
      field: "Notes",
      headerName: "Notes",
      description: "Notes",
      flex: 2,
    },
    {
      field: "Job",
      headerName: "Job",
      description: "The Job the message belongs to",
      flex: 1.5,
      renderCell: ({ value }) => value,
    },
    {
      field: "id",
      headerName: "FireStore ID",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "RawMessageID",
      headerName: "RawMessage ID",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "ProviderMessageID",
      headerName: "ProviderMessage ID",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        id: false,
        RawMessageID: false,
        ProviderMessageID: false,
      },
    },
  };

  /*function test(selectedRowIDs) {
    return selectedRowIDs
  }
  */

  return (
    <>
      <Paper sx={{ marginBottom: 1, padding: 2 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <MessagesIcon color="secondary" />
          <Typography variant="h2">Messages</Typography>
        </Stack>
      </Paper>
      <Box sx={{ flexGrow: 1 }}>
        <DataLoader
          collection={fscollection}
          columns={columns}
          individualPath={"/Messages/"}
          initialState={initialState}
          Card={DataCard}
        //onRowSelectionChange={test}
        />
      </Box>
    </>
  );
}
function DataCard({ data, individualPath }) {
  const { tryNavigateTo } = useNavigation();

  return (
    <Card sx={{ midwidth: 275 }}>
      <CardActionArea
        onClick={() => {
          tryNavigateTo(individualPath + data.id);
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>
                {NameFromPhoneNumber(data.FromNumber)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{TimeConversion(data.ReceivedAt)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{data.Text}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Images: {data.Images.length}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

import { Alert, AlertTitle, Box, Button, Grid, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { updateDoc } from 'firebase/firestore';
import React, { createContext, useContext, useState } from 'react';
import { useFirestoreDocData } from 'reactfire';
import { useNavigation } from "../FireBaseApp/Navigation";

const EditorPageContext = createContext()
export function useEditorPageContext() { return useContext(EditorPageContext) }

export function EditorPage({ itemName, document, children }) {
    const { status, data } = useFirestoreDocData(document, { idField: 'id' });

    if (status === 'success' && data) {
        return (
            <EditorPageInner itemName={itemName} data={data} document={document}>
                {children}
            </EditorPageInner>
        )
    }
    if (status === 'success') {
        return <div>No Data</div>
    }
    return <div>Loading Data</div>
}

function EditorPageInner({ data, itemName, document, children }) {
    const { tryNavigateTo, setBlock, isBlocked } = useNavigation()
    const [errorMessage, setErrorMessage] = useState("")
    const [newData, setNewData] = useState(data)
    const theme = useTheme()
    const isMedium = useMediaQuery(theme.breakpoints.up('md'));

    //TODO add protection for clearing the fields
    function setValue(key, value) {
        if (newData[key] !== value) {
            let newTempData = { ...newData }
            newTempData[key] = value
            setNewData(newTempData)
            setBlock(true)
        }
    }
    function setBackToDefaultValues() {
        setNewData(data)
        setBlock(false)
    }
    const saveChanges = async () => {
        setErrorMessage("");
        //TODO update document with newData
        await updateDoc(document,
            { ...newData }).then(() => {
                setBlock(false)
            }).catch((error) => {
                setErrorMessage(error.message)
            })
    }

    return (
        <EditorPageContext.Provider value={{ setValue, data: newData }}>
            <Paper sx={{ marginBottom: 1, padding: 2 }}>
                <Stack direction='row' justifyContent={'space-between'} sx={{ marginBottom: 2 }}>
                    <Box>
                        <Typography variant="h2">{itemName}</Typography>
                        <Stack direction='row' alignItems='center' spacing={1}>
                            <Button size='small' onClick={() => { tryNavigateTo("/" + itemName + "s") }}>all {itemName}s</Button>
                        </Stack>
                    </Box>
                    <Box >
                        {errorMessage && <Alert severity="error"><AlertTitle>Error</AlertTitle>{errorMessage}</Alert>}
                        {isBlocked && <>
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <Alert severity="warning"> Warning - You have unsaved changes</Alert>
                                <Button color='success' variant='contained' onClick={saveChanges}>Save</Button>
                                <Button color='error' variant='contained' onClick={setBackToDefaultValues}>Reset</Button>
                            </Stack>
                        </>}
                    </Box >
                    {isMedium && <Box></Box>}
                </Stack>
                <Grid container spacing={1}>
                    {data !== undefined && children}
                </Grid>
            </Paper >
        </EditorPageContext.Provider >
    )
}
import { AppBar, Avatar, Box, IconButton, List, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React, { useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { ListItem, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';

import { useLocation } from 'react-router-dom';
import { useAuthData, useBAccountProfile, useBAccountSelector, useUserData, useUserProfile } from '../FireBaseApp/AuthProtector';
import { useFirebaseAppContext } from '../FireBaseApp/FireBaseApp';
import { useNavigation } from '../FireBaseApp/Navigation';


const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Layout({ children, pages, userMenu }) {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const signOut = useAuthData();
  const bAccountProfile = useBAccountProfile();
  const userProfile = useUserProfile();
  const userData = useUserData();
  const { ExitBAccount } = useBAccountSelector();
  const { tryNavigateTo } = useNavigation()
  const location = useLocation();

  const { logo } = useFirebaseAppContext()

  const [menuOpen, setMenuOpen] = useState(!(userProfile.menuOpenByDefault !== undefined && !userProfile.menuOpenByDefault));
  const [anchorEl, setAnchorEl] = useState(null);

  if (isMedium && menuOpen) {
    setMenuOpen(false)
  }

  return (
    <Box sx={{ display: 'flex', height: '100vh', width: '100vw' }}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          {!isMedium && <Box>
            <IconButton color="inherit" aria-label="open drawer" onClick={() => { setMenuOpen(!menuOpen); }} edge="start" sx={{ marginRight: '36px' }}>
              <MenuIcon />
            </IconButton>
          </Box>}
          <Box component="img" sx={{ height: '40px' }} src={logo} />
          <Box sx={{ flexGrow: 1 }}>
          </Box>
          {!isMedium && <Box>
            <Typography variant="h2" noWrap component="div">
              {bAccountProfile?.Name}
            </Typography>
          </Box>}
          <Box>
            <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" color="inherit"
              onClick={(event) => { setAnchorEl(event.currentTarget); }}>
              <Avatar src={userProfile.image}>
                {userProfile?.Name ? userProfile?.Name[0] : null}
              </Avatar>
            </IconButton>
            <Menu id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={Boolean(anchorEl)}
              onClose={() => { setAnchorEl(null); }}
            >
              {userMenu.map(({ name, path }) =>
              (
                <MenuItem key={String(name)} onClick={() => {
                  setAnchorEl(null)
                  tryNavigateTo(path)
                }}>{name}</MenuItem>
              )
              )}
              {userData?.BAccountIDs && userData?.BAccountIDs.length > 1 &&
                <MenuItem onClick={() => {
                  ExitBAccount()
                }}> Change Company </MenuItem>}
              <MenuItem onClick={() => {
                setAnchorEl(null)
                signOut()
              }}>Sign Out</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={menuOpen} >
        <DrawerHeader />
        <List>
          {pages.map(({ name, path, icon }) =>
          (icon &&
            <ListItem button key={String(name)} onClick={() => tryNavigateTo(path)} selected={location.pathname === path || (path !== '/' && location.pathname.startsWith(path))}>
              <ListItemIcon> {icon} </ListItemIcon>
              <ListItemText primary={name} />
            </ListItem>)
          )}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
          <DrawerHeader />
          {children}
        </Box>
      </Box>
    </Box>
  );
}

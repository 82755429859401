import { Paper, Stack, Typography } from '@mui/material';
import React from 'react';
export default function ScreenTooSmall() {

    return (
        <Paper sx={{ width: "100%", height: "100%" }}>
            <Stack alignItems='center' justifyContent='center' sx={{ height: '100%' }}>
                <Typography variant="h2">Sorry!</Typography>
                <Typography variant="h4">This page is best viewed on a large screen</Typography>
            </Stack>
        </Paper>
    )
}
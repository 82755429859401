import { useTheme } from '@emotion/react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from '@mui/material';
import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';


const NavigationContext = createContext()
export function useNavigation() { return useContext(NavigationContext) }

export function NavigationProvider({ children }) {
  return (
    <InternalNavigation>
      {children}
    </InternalNavigation>
  )
}

function InternalNavigation({ children }) {
  const navigateToRouter = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [isBlocked, setIsBlocked] = useState(false)
  const [requestedPath, setPath] = useState(undefined)
  const [alert, setAlert] = useState(undefined)

  const tryNavigateTo = (path) => {
    if (!isBlocked) {
      navigateTo(path)
    } else {
      setPath(path)
      setAlert({ "path": path, "time": Date.now() })
    }
  }

  const navigate = () => {
    let tempPath = requestedPath;
    setDefaults()
    navigateToRouter(tempPath)
  }

  const navigateTo = (path) => {
    setDefaults()
    navigateToRouter(path)
  }

  const setBlock = (enabled) => {
    setIsBlocked(enabled)
    if (!enabled) {
      setAlert(undefined)
    }
  }

  const clearAlert = () => {
    setAlert(undefined)
  }

  const setDefaults = () => {
    setIsBlocked(false)
    setPath(undefined)
    setAlert(undefined)
  }

  return (
    <NavigationContext.Provider value={{ tryNavigateTo, navigate, navigateTo, setBlock, isBlocked }}>
      {children}
      <Dialog
        fullScreen={fullScreen}
        open={alert != undefined}
        onClose={clearAlert}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Lose your unsaved changes?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to leave this page?
            You will lose your changes.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={clearAlert}>
            NO, Take me Back
          </Button>
          <Button onClick={navigate}>
            YES
          </Button>
        </DialogActions>
      </Dialog>
    </NavigationContext.Provider >
  )
}
import { useTheme } from '@emotion/react';
import { Box, Grid, Paper, Tab, Tabs, TextField, Typography, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import DefaultsIcon from '@mui/icons-material/BuildCircle';
import UsersIcon from '@mui/icons-material/Group';
import AlertsIcon from '@mui/icons-material/NotificationsActive';

import ScreenTooSmall from '../../components/ScreenTooSmall';

import { useBAccountData, useBAccountProfile } from '../../FireBaseApp/AuthProtector';
import { ArrayEditor } from '../../components/Editors/ArrayEditor';

export default function Settings() {
  const BAccountProfile = useBAccountProfile();
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <Paper sx={{ marginBottom: 1, padding: 2 }}>
        <Typography variant="h2">{BAccountProfile.Name} Company Settings</Typography>
      </Paper>
      <Box sx={{ flexGrow: 1 }}>
        {isMedium ?
          <SettingsPanel /> : <ScreenTooSmall />
        }
      </Box>
    </>
  );
}

function SettingsPanel() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (<Paper sx={{ width: "100%", height: "100%" }}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="fullWidth">
        <Tab label="Defaults" icon={<DefaultsIcon />} />
        <Tab label="Users" icon={<UsersIcon />} />
        <Tab label="Alerts" icon={<AlertsIcon />} />
      </Tabs>
    </Box>
    <Defaults value={value} />
    <Users value={value} />
    <Alerts value={value} />
  </Paper>
  )
}

function Defaults({ value }) {
  const BAccountProfile = useBAccountProfile()
  return (
    <TabPanel value={value} index={0}>
      <Grid container spacing={1} item xs={12} lg={6} >
        <Grid item xs={12} md={3}>
          <TextField fullWidth label={"Job Number Prefix"} inputProps={{ readOnly: true }}
            value={BAccountProfile?.JobPrefix} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField fullWidth label={"Next Job Number"} inputProps={{ readOnly: true }}
            value={BAccountProfile?.NextJobNumber} />
        </Grid>
      </Grid>
    </TabPanel>
  )
}
function Users({ value }) {
  const BAccountData = useBAccountData();
  return (
    <TabPanel value={value} index={1}>
      <ArrayEditor title={"Approved Senders"} data={BAccountData.Numbers}></ArrayEditor>
    </TabPanel>
  )
}
function Alerts({ value }) {
  return (
    <TabPanel value={value} index={2}>
      Alerts
    </TabPanel>
  )
}
function TabPanel({ children, value, index }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

/*
Examples
Object.entries(bAccountProfile.orderStatuses).forEach(([key,value]) =>{
  if(value.name === undefined){
    value.name = key
  }
  if(value.isOrderStatus){
    orderStatuses.push(<Chip key={value.name+"_1"} label={value.name} color={value.color}/>)
  }
  if(value.isLoadStatus){
    loadStatuses.push(<Chip key={value.name+"_2"} label={value.name} color={value.color}/>)
  }
  if(value.isBillingStatus){
    billingStatuses.push(<Chip key={value.name+"_3"} label={value.name} color={value.color}/>)
  }
})
*/
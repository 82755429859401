import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from '@mui/material';
import React from 'react';

import { useBAccountData, useBAccountProfile, useUserData, useUserProfile } from '../../FireBaseApp/AuthProtector';

export default function UserProfile() {
  const userProfile = useUserProfile()
  const bAccountData = useBAccountData()
  const bAccountProfile = useBAccountProfile()
  const userData = useUserData()


  return (
    <>
      <Stack justifyContent={"space-between"} height={"100%"}>
        <Box>
          <Typography variant="h2">User Profile: {userProfile.email}</Typography>
          <Typography>Name: <span>{userProfile.name}</span></Typography>
        </Box>
        <Box>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>Nerd Data</AccordionSummary>
            <AccordionDetails>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>User Profile</AccordionSummary>
                <AccordionDetails>{JSON.stringify(userProfile)}</AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>User Data</AccordionSummary>
                <AccordionDetails>{JSON.stringify(userData)}</AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>BAccount Profile</AccordionSummary>
                <AccordionDetails>{JSON.stringify(bAccountProfile)}</AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>BAccount Data</AccordionSummary>
                <AccordionDetails>{JSON.stringify(bAccountData)}</AccordionDetails>
              </Accordion>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Stack>
    </>
  );
}
import useSound from "use-sound";
import jobsdone from '../../Static/jobs-done.mp3';
import morework from '../../Static/more-work.mp3';

import React from 'react';
import { useParams } from 'react-router-dom';
import { useBAccountData } from '../../FireBaseApp/AuthProtector';

import { doc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import { TimeConversion } from '../../components/BAccountConversions/TimeConversion';


import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';


import { EditorPage, useEditorPageContext } from '../../components/EditorPage';

export default function Job() {
  const bAccountData = useBAccountData()
  const firestore = useFirestore()

  const [playJobsdone] = useSound(jobsdone)
  const [playMorework] = useSound(morework)
  const { JobID } = useParams()
  const document = doc(firestore, 'BAccounts', bAccountData.id, 'Jobs', JobID);

  return (
    <EditorPage itemName="Job" document={document} >
      <JobDetails />
      <Button onClick={playJobsdone}>Jobs Done</Button>
      <Button onClick={playMorework}>More Work</Button>
    </EditorPage>
  );
}

function JobDetails() {
  const { setValue, data } = useEditorPageContext()


  return (
    <>
      <Grid container spacing={1}>
        <Grid container spacing={1} item xs={12} lg={6} >
          <Grid item xs={12} md={4}>
            <TextField fullWidth label={"JobID"} inputProps={{ readOnly: true }}
              value={data?.JobID} />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField fullWidth id="Customer" label={"Customer"}
              value={data?.Customer}
              onChange={(event) => { setValue(event.target.id, event.target.value) }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField fullWidth label={"Created"} inputProps={{ readOnly: true }}
              value={TimeConversion(data?.Created)} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth id="ReferenceID" label={"ReferenceID"}
              value={data?.ReferenceID}
              onChange={(event) => { setValue(event.target.id, event.target.value) }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="Status-Label">Status</InputLabel>
              <Select
                labelId="Status-Label"
                id="Status"
                value={data?.Status}
                label="Age"
                onChange={(event) => { setValue("Status", event.target.value) }}
              >
                <MenuItem value={"Not Started"}>Not Started</MenuItem>
                <MenuItem value={"In Progress"}>In Progress</MenuItem>
                <MenuItem value={"Invoiced"}>Invoiced</MenuItem>
                <MenuItem value={"Paid"}>Paid</MenuItem>
                <MenuItem value={"Complete"}>Complete</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid >
        <Grid container spacing={1} item xs={12} lg={6}>
          <Grid item xs={12} >
            <TextField fullWidth multiline minRows={4} id="Notes" label="Notes"
              value={data?.Notes}
              onChange={(event) => { setValue(event.target.id, event.target.value) }} />
          </Grid >
        </Grid>
      </Grid >
    </>
  )
}
/*
function File({ name, path }) {
  const storage = useStorage();
  const filePath = path + "/" + name
  const fileReference = ref(storage, filePath)

  const [errorMessage, setErrorMessage] = useState("")
  const [status, setStatus] = useState("Loading...")
  const [url, setURL] = useState()

  useEffect(() => {
    GetDownloadLink()
  }, [])

  const FileChange = (event) => {
    const file = event.target.files[0]
    setStatus("Uploading... " + file.name)
    uploadBytes(fileReference, file)
      .then((snapshot) => {
        GetDownloadLink()
      })
      .catch((error) => {
        setErrorMessage(error.Message)
      })
  }
  const GetDownloadLink = () => {
    setStatus("Loading...")
    getDownloadURL(fileReference)
      .then((url) => {
        setStatus()
        setURL(url)
      })
      .catch((error) => {
        setStatus()
        switch (error.code) {
          case 'storage/object-not-found':
            setURL()
            break;
          default:
            console.log(JSON.stringify(error))
            setErrorMessage(error.Message)
        }
      })
  }

  return (
    <>
      <Stack direction='row' spacing={1} sx={{ width: "100%" }}>
        {errorMessage && <Alert sx={{ width: "100%" }} severity="error">{name} - {errorMessage}</Alert>}
        {!errorMessage && <Typography component='span' color="primary" sx={{ fontWeight: 500 }}>{name}</Typography>}
        {status ? status :
          (url ? <a href={url} target="_blank" rel="noreferrer">View</a> :
            <input type="file" onChange={FileChange} />)
        }
      </Stack>
    </>
  )
}
*/
import { Typography } from '@mui/material';
import React, { Component } from 'react';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <Typography variant="h3">Oops, did I do that?</Typography>
          <Typography variant="h4">Your error was reported to us and we are working to fix it.</Typography>
        </>
      );
    }
    return this.props.children;
  }
}
import { limit, orderBy, query } from 'firebase/firestore';
import React from 'react';
import { useFirestoreCollectionData } from 'reactfire';

//import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, useGridApiRef } from '@mui/x-data-grid';
import { DataGridPro as DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, useGridApiRef } from '@mui/x-data-grid-pro';
import { useNavigation } from '../FireBaseApp/Navigation';

import { useTheme } from '@emotion/react';
import { Paper, Stack, useMediaQuery } from '@mui/material';

import { useEffect } from 'react';
import ScreenTooSmall from './ScreenTooSmall';


export function DataLoader({ collection, columns, individualPath, initialState, Card, onRowSelectionChange }) {
    const theme = useTheme()
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));
    if (collection === undefined || columns === undefined || individualPath === undefined) {
        return <div>Error with Setup</div>;
    }
    //Protect against Small Screen and No Card Defined
    if (isMedium && Card === undefined) {
        return <ScreenTooSmall />
    }

    return (
        <>
            {isMedium ?
                <DataCardLoader
                    individualPath={individualPath}
                    columns={columns}
                    initialState={initialState}
                    collection={collection}
                    Card={Card} />
                : <DataTableLoader
                    individualPath={individualPath}
                    columns={columns}
                    initialState={initialState}
                    collection={collection}
                    onRowSelectionChange={onRowSelectionChange} />
            }
        </>
    )

}
function DataTableLoader({ individualPath, collection, columns, initialState, onRowSelectionChange }) {
    //TODO figure out a better way for this limit
    let dataQuery = query(collection, orderBy("Created", "desc"), limit(50));

    let { status, data } = useFirestoreCollectionData(dataQuery, {
        idField: 'id',
    });
    if (status !== 'success' || data === undefined) {
        return <div>loading...</div>;
    }

    return (
        <DataTable
            individualPath={individualPath}
            columns={columns}
            initialState={initialState}
            rows={data}
            onRowSelectionChange={onRowSelectionChange} />
    )
}

function DataTable({ individualPath, rows, columns, initialState, onRowSelectionChange }) {
    const { tryNavigateTo } = useNavigation()
    const apiRef = useGridApiRef()

    const onRowDoubleClick = ({ row }) => {
        tryNavigateTo(individualPath + row.id)
    }

    useEffect(() => {
        const handleEvent = (params) => {
            if (onRowSelectionChange !== undefined) {
                onRowSelectionChange(params)
            }
        }

        return apiRef.current.subscribeEvent('rowSelectionChange', handleEvent);
    }, [apiRef, onRowSelectionChange])


    return (
        <Paper sx={{ width: "100%", height: "100%" }}>
            <DataGrid
                apiRef={apiRef}
                initialState={initialState}
                density='compact'
                rows={rows}
                columns={columns}
                editable
                autoPageSize
                pagination
                checkboxSelection
                disableRowSelectionOnClick
                onRowDoubleClick={onRowDoubleClick}
                disableSelectionOnClick
                components={{
                    Toolbar: Toolbar
                }}
            />
        </Paper>

    )
}
function Toolbar() {
    return <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
}


function DataCardLoader({ individualPath, collection, columns, initialState, onRowSelectionChange, Card }) {
    let dataQuery = query(collection,
        orderBy("Created", "desc"),
        limit(10));
    let { status, data } = useFirestoreCollectionData(dataQuery, {
        idField: 'id',
    });
    if (status !== 'success' || data === undefined) {
        return <div>loading...</div>;
    }

    return (
        <DataCards
            individualPath={individualPath}
            columns={columns}
            initialState={initialState}
            rows={data}
            onRowSelectionChange={onRowSelectionChange}
            Card={Card} />
    )
}
function DataCards({ individualPath, rows, columns, initialState, Card }) {
    return (
        <Stack spacing={1}>
            {rows.map((rowData) =>
                <Card key={rowData.id} individualPath={individualPath} data={rowData} columns={columns} initialState={initialState} />
            )}
        </Stack>)
}


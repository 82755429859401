import React from 'react';
import { Route } from 'react-router-dom';

import { Routes } from 'react-router-dom';
import { FirebaseApp } from './FireBaseApp/FireBaseApp';

import Layout from './pages/Layout';

import { AppThemeProvider } from './theme.js';

import logo from './Static/JobsDone.png';
import signinImage from './Static/Login.jpg';

//Dashboard
import DashboardIcon from '@mui/icons-material/Home';
import Dashboard from './pages/Dashboard';
//Messages
import MessagesIcon from '@mui/icons-material/Email';
import Message from './pages/Messages/Message';
import Messages from './pages/Messages/Messages';
//Jobs
import JobsIcon from '@mui/icons-material/Work';
import Job from './pages/Jobs/Job';
import JobCreate from './pages/Jobs/JobCreate';
import Jobs from './pages/Jobs/Jobs';

//Settings
import SettingsIcon from '@mui/icons-material/Settings';
import Settings from './pages/Settings/Settings';
import UserProfile from './pages/Settings/UserProfile';

const userMenu = [
  { name: "Edit Profile", path: "/UserProfile", element: <UserProfile /> },
]

const pages = [
  { name: "Dashboard", path: "/", icon: <DashboardIcon />, element: <Dashboard />, default: true },

  { name: "Jobs", path: "/Jobs", icon: <JobsIcon />, element: <Jobs /> },
  { name: "Job", path: "/Jobs/:JobID", element: <Job /> },
  { name: "JobCreate", path: "/Jobs/Create", element: <JobCreate /> },

  { name: "Messages", path: "/Messages", icon: <MessagesIcon />, element: <Messages /> },
  { name: "Message", path: "/Messages/:MessageID", element: <Message /> },

  { name: "Settings", path: "/Settings", icon: <SettingsIcon />, element: <Settings /> },
]

function App() {
  return (
    <AppThemeProvider>
      <FirebaseApp logo={logo} signinImage={signinImage}>
        <Layout pages={pages} userMenu={userMenu}>
          <Routes>
            {userMenu.map((page) =>
              <Route key={page.name} path={page.path} element={page.element} />
            )}
            {pages.map((page) =>
              <Route key={page.name} path={page.default ? "*" : page.path} element={page.element} />
            )}
          </Routes>
        </Layout>
      </FirebaseApp>
    </AppThemeProvider>
  );
}

export default App;

import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import React from 'react';

import { AuthProvider, DatabaseProvider, FirestoreProvider, StorageProvider, useFirebaseApp } from 'reactfire';

export function FirebaseComponents({ children }) {
  const app = useFirebaseApp();
  const authInstance = getAuth(app);
  const firestoreInstance = getFirestore(app);
  const databaseInstance = getDatabase(app);
  const storageInstance = getStorage(app);

  return (
    <AuthProvider sdk={authInstance}>
      <FirestoreProvider sdk={firestoreInstance}>
        <StorageProvider sdk={storageInstance}>
          <DatabaseProvider sdk={databaseInstance}>
            {children}
          </DatabaseProvider>
        </StorageProvider>
      </FirestoreProvider>
    </AuthProvider>
  );
}
